html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #0F172A;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

* {
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  color: #eee;
}

@keyframes grow{
  0% {	-webkit-transform: scale(.0001) rotateZ(360deg) ; }
  100% {	-webkit-transform: scale(.99) rotateZ(-360deg); }
}

#easter-egg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  -webkit-animation: grow 2200ms infinite;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  max-width: 512px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 420px) {
  html body #root .App .Header h1.Logo {
    font-size: 19px;
    margin-top: 3px;
  }
  html body #root .App .Header h1.Logo span {
    display: none;
  }
  html body #root .App .Header {
    margin-bottom: 2px;
  }
  html body #root .App .Header {
    padding: 0 5px 0 10px;
  }
  /* html body #root .App .PlayArea .guess-result {
    padding-top: 4px;
  } */
}

@media screen and (max-width: 520px) {
  html body #root .App .PlayArea {
    padding: 0 10px;
  }
  .countdownControls {
    padding: 0 10px;
  }
  .game_content {
    padding: 0 10px;
  }
}

@media screen and (max-width: 440px) {
  .PlayArea div.input-area {
    display: flex;
    flex-direction: row;
  }

  .play-area-btns button {
    width: 50%;
    margin: 5px !important;
  }
}

@media screen and (max-width: 284px) {
  html body #root .App .PlayArea .input-area .game-input {
    font-size: 16px;
  }
}

@media screen and (max-width: 300px) {
  html body #root .App .PlayArea .guess-result {
    font-size: 16px;
    height: 29px;
  }

  .kofi-link, .miss-a-day-link, .submit-link {
    padding: 0 10px;
  }
}

@media screen and (max-width: 320px) {
  html body #root .App .Header h1.Logo {
    font-size: 16px;
    position: relative;
    top: 3px;
  }
}

.modal-bg {
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.75);
}

.modal {
  color: #eee;
  border-radius: 5px;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(51, 65, 85, 1);
  border: 1px solid #eee;
  z-index: 99999;
}

.submit-link {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.about-modal {
  width: auto !important;
  max-width: 500px;
}

@media screen and (max-width: 530px) {
  .about-modal {
    margin: 20px;
  }

  .howtoplay-modal {
    margin: 20px;
  }
}

.play-area-btns {
  display: flex;
  flex-direction: row;
}

.howtoplay-modal {
  width: auto !important;
  max-width: 500px;
}
.stats-modal {
  height: 405px;
}

.footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.socials {
  display: flex;
  flex-direction: row;
}

.modal h3, .modal p {
  padding: 0;
  margin: 0;
}

.modal h3 {
  margin-bottom: 10px;
}
.guesses-remaining {
  padding: 0;
  color: #eee;
  text-align: center;
  margin: 5px 0 0;
}

.modal p {
  margin-bottom: 20px;
}

.modal-inner {
  position: relative;
}

.modal p a {
  color: white;
}

.modal button {
  background: none;
  border: 0;
  color: white;
  position: absolute;
  right: 0;
  top: 10px;
}

.about-modal button {
  position: absolute;
  right: 0;
  top: 10px;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
}

button {
  cursor: pointer;
}

svg {
  transition: opacity 150ms ease;
  opacity: 0.8;
}

svg:hover {
  opacity: 1;
}

.Header {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Header button {
  background: none;
  border: 0;
  color: #eee;
  align-self: flex-end;
  padding:3px;
}

.Header button svg {
  height: 28px;
}

.Logo {
  color: #eee;
  font-size: 28px;
}

.love-btn {
  margin-left: auto;
}

.stats-btn {
  /*margin-left: auto;*/
}

.stats-section {
  text-align: center;
}

.image-area {
  max-width: 512px;
  width: 100%;
  height: auto;
  position: relative;
  min-height: 280px;
}

@media screen and (max-width: 440px) {
  .image-area {
    min-height: 200px !important;
  }
  .image-area div.game-image {
    height: 200px !important;
  }
}

@media screen and (max-width: 330px) {
  .image-area {
    min-height: 160px !important;
  }
  .image-area div.game-image {
    height: 160px !important;
  }
}

.image-area div.game-image {
  border-radius: 5px;
  width: 100%;
  max-width: 512px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.guess-hint {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 5px;
  color: #eee;
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(0,0,0,0.4);
  padding: 5px 10px 7px 10px;
}

/*.image-area img:first-of-type {*/
/*  position: relative !important;*/
/*}*/

.image-selector {
  margin-top: 10px;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.image-selector .gamecube {
  border: 0;
  height: 36px;
  width: 26px;
  margin: 5px;
  border-radius: 4px;
  font-size: 20px;
  color: #eee;
  opacity: 0.8;
  transition: opacity 150ms ease;
  background-color: rgba(30, 41, 59, 1);
  text-align: center;
  padding: 0;
}

.image-selector button:hover {
  opacity: 1;
}

.image-selector button.locked {
  opacity: 0.3;
  cursor: not-allowed;
}

.image-selector button.active {
  background-color: rgba(71, 85, 105, 1);
  font-weight: 900;
}

.PlayArea {
  margin-top: 15px;
}

.input-area {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.MuiOutlinedInput-root {
  border: 1px solid #eee;
  background-color: #eee;
}

.input-area input:focus {
  outline: none !important;
}

@media screen and (max-width: 820px) {
  #fs-slot-footer-wrapper .__fs-branding {
    top: -32px !important;
    left: 0 !important;
  }
}

.input-area {
  position: relative;
}

.input-area input {
  border: solid 1px #eee;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 20px;
  height:36px;
}
.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions-scrollable-headsup {
  position: absolute;
  bottom: 5px;
  right: -5px;
  height: 50px;
  width: 50px;
  pointer-events: none;
}

.suggestions-label {
  position: absolute;
  top: 39px;
  text-align: left !important;
  margin: 0;
  padding: 0;
  font-weight: bold;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 40px;
  padding: 5px 5px 5px 8px;
  text-decoration: underline;
  max-width: 420px;
  color: gray !important;
}

.suggestions {
  border-radius: 5px;
  /* border: 1px solid #999; */
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;
  width: 100%;
  z-index: 8000;
  max-width: 420px;
  position: absolute;
  top: 64px;
  left: 0px;
  background-color: white;
  /* border-top-left-radius: 0;
  border-top-right-radius: 0; */
}
.suggestions li {
  padding: 0.5rem;
  opacity: 1;
  background-color: white;
}
.suggestion-active,
.suggestions li:hover {
  background-color: #eee;
  cursor: pointer;
  font-weight: 700;
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.input-area .play-area-submit, .input-area .play-area-skip {
  height: 36px;
  border: 0;
  padding: 0 10px;
  margin-left: 10px;
  color: #eee;
  border-radius: 5px;
  background-color: rgba(6, 78, 59, 1);
  font-size: 20px;
  white-space: nowrap;
}

.mainButton {
  border: 0;
  padding : 0 10px;
  color: #eee;
  border-radius: 5px;
  font-size: 20px;
  white-space: nowrap;
}

.submitButton {
  height : 36px;
  width: 100%;
  background-color: rgba(6, 78, 59, 1);
  margin-bottom: 10px;
}

.iconButton {
  height : 36px;
  margin-top:5px;
  background-color: #2f4f4f;
  visibility: visible;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}

.input-area .play-area-submit:hover {
  background-color: rgba(6, 78, 59, 0.8);
}
.input-area .play-area-submit:active {
  background-color: rgba(6, 78, 59, 0.6);
}

.input-area .play-area-skip {
  background-color: rgba(47,79,79,1);
}

.input-area .play-area-skip:hover {
  background-color: rgba(47,79,79,0.8);
}

.input-area .play-area-skip:active {
  background-color: rgba(47,79,79,0.6);
}

.PlayArea .guess-result {
  width: 100%;
  border: 1px solid rgba(255,255,255,0.75);
  height: 36px;
  border-radius: 5px;
  margin-bottom: 5px;
  color: #eee;
  font-size: 20px;
  white-space: nowrap;
  padding: 3px 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* line-height: 32px; */
}

.PlayArea p {
  margin: 0;
  width: 100%;
  color: rgba(255,255,255,0.75);
  text-align: center;
}

.shakeme {
  animation: shake 0.5s;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result h2, .result h3 {
  color: #eee;
  margin: 0;
  padding: 0;
  animation: shake 0.5s;
  margin-bottom: 10px;
}

.result .answer {
  display: inline-block;
  color: rgba(5, 150, 105, 1);
}

.result h2.win-msg {
  color: rgba(5, 150, 105, 1);
  margin-top: 5px;
  font-size: 36px;
  text-align: center;
}

.result h2.lose-msg {
  color: rgba(185, 28, 28, 1);
  margin-top: 5px;
  font-size: 36px;
  text-align: center;
}

.emoji-track {
  display: flex;
  flex-direction: row;
}

.emoji-track .guess-cube {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: rgba(100, 116, 139, 1);
  margin: 5px;
}

.emoji-track .guess-cube.success {
  background-color: rgba(5, 150, 105, 1);;
}

.emoji-track .guess-cube.fail {
  background-color: rgba(185, 28, 28, 1);
}

.stats-modal .stat {
  text-align: center;
  display: inline-block;
  padding: 5px;
  margin: 0 15px;
}

.stat-value {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.stat-label {
  font-size: 14px;
  color: #eee;
}

.end-of-game-guesses-summary {
  color: #eee;
}

.guess-distribution {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.guess-distribution-row {
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-bottom: 5px;
}

.guess-distribution-row p {
  margin-right: 10px;
}

.guess-distribution-row .guess-row-bar {
  text-align: center;
  background-color: #0F172A;
  padding: 0 5px;
}

.share-results-btn, .play-prev-day-btn {
  background-color: rgba(5, 150, 105, 1);
  font-size: 24px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  transition: opacity 100ms ease;
  margin-right: 20px;
}

.share-results-btn:hover, .play-prev-day-btn {
  opacity: 0.8;
}

.share-results-btn:active, .play-prev-day-btn {
  opacity: 0.6;
}

.play-prev-day-btn {
  background-color: rgba(5, 150, 105, 1);
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  color: #eee;
  padding: 2px 6px;
  opacity: 1;
  transition: opacity 100ms ease;
  position: relative;
  top: -13px;
  white-space: nowrap;
  text-overflow: clip;
  width: 136px;
}

.play-prev-day-btn:hover {
  opacity: 0.8;
}

.play-prev-day-btn:active {
  opacity: 0.6;
}

.countdown-label {
  margin: 0;
  padding: 0;
  margin-top: 0;
  color: #eee;
}

.socials-label {
  text-decoration: none;
  padding: 10px;
  font-size:24px;
}

.end-of-game-guesses-summary:last-of-type {
  margin-bottom: 10px;
}

.countdown-to-next-game {
  color: #eee;
  font-size: 24px;
}

.end-of-game-guesses-summary-label {
  color: #eee;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}


p.kofi-link {
  text-align: center;
  font-size: 12px;
  color: #eee;
}

p.kofi-link a a {
  color: #eee;
}

.miss-a-day-link {
  margin: 0;
  padding: 0;
}

.previous-games-header, .prev-game-result-row {
  display: flex;
  flex-direction: row;
  color: #eee;
}

.previous-games .back-to-today {
  width: 100%;
  text-align: center;
}

.previous-games .back-to-today button {
  font-size: 20px;
}

.prev-game-result-row {
  margin-bottom: 3px;
  height: 27px;
  padding-top: 2px;
}

.prev-game-result {
  text-align: center;
}


@media screen and (max-width: 550px) {
  .previous-games .prev-game-legend {
    flex-direction: column;
  }

  .prev-game-result-row {
    /* flex-direction: column; */
    /* height: auto; */
  }

  .emoji-track.prev-game-emoji-track {
    position: relative;
    top: -2px !important;
  }

  .prev-game-day-number {
    width: 40px !important;
    position: relative;
    top: -2px !important;
  }

  .prev-game-emoji-track {
    width: 130px !important;
  }

  .guess-cube {
    margin: 1px !important;
  }

  .unplayed-qmark {
    margin: 0 1px !important;
    top: 2px !important;
  }

  .result-text-supplementary {
    display: none;
  }

  .day-text {
    display: none;
  }

  .prev-game-result {
    width: 23px !important;
  }

  .prev-game-result.success, .prev-game-result.fail {
    position: relative;
    top: -2px !important;
  }

  .prev-game-result.pending {
    position: relative;
    /* left: 2px !important; */
  }

  .play-prev-game-btn {
    width: 115px;
    position: relative;
  }

  .play-prev-game-btn button {
    width: 115px;
    position: absolute;
    right: 0;
  }
}

.previous-games-header {
  margin-bottom: 10px;
}

.previous-games-header .day-result {
  width: 310px !important;
  text-align: center;
}

.prev-game-legend {
  color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.unplayed-qmark {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(100, 116, 139);
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  margin: 5px;
}

.prev-game-legend span {
  position: relative;
  top: 4px;
}

.qmark-emojitrack {
  position: relative;
  top: -4px !important;
}

.prev-game-legend .emoji-track {
  margin-right: 15px;
}

.previous-games-header .day-number, .prev-game-result-row .prev-game-day-number {
  width: 50px;
}

.prev-game-result-row {
  cursor: pointer;
  padding-top: 4px;
  transition: background-color 300ms ease;
  justify-content: center;
}

.prev-game-result-row:hover {
  background-color: #2c447d;
}

.previous-games-header .day-emojis, .prev-game-result-row .prev-game-emoji-track {
  position: relative;
  top: -5px;
}

.previous-games-header .day-result, .prev-game-result-row .prev-game-result {
  width: 150px;
  position: relative;
  top: -1px;
}

.footer {
  margin-bottom: 375px;
}
@media (max-width: 767px) {
  .footer {
    margin-bottom: 50px;
  }
}

.share-icon {
  width: 24px;
  padding-top: 5px;
  padding-right: 5px;
}

.game-info{
  color: #eee;
  font-size: 14px;
}

.logo-link {
  text-decoration: none;
  color: #eee;
}

.linkaff-list{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    list-style-type: none;
}

.guess-image-success{
  background-color: rgba(5, 150, 105, 1) !important;
}

.guess-image-fail {
  background-color: rgba(186, 28, 28, 1) !important;
}
sup{
    font-size: 16px;
}

/*for the side vertical ads make it so that they only display on a certain width or greater*/
@media screen and (max-width: 1470px){
  .fs-sidewall-container{
    display: none;
  }
}


.dropdown-icon {
  vertical-align: middle;
  transition: transform 0.3s ease-in-out;
}

.dropdown-icon.rotated {
  transform: rotate(180deg);
}

.control-emoji{
   font-size:25px;
 }

 .control-emoji, .right{
   margin-left:25px;
 }

 .control-emoji, .left {
   margin-right:25px;
 }
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9enp3k');
  src:  url('fonts/icomoon.eot?9enp3k#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9enp3k') format('truetype'),
    url('fonts/icomoon.woff?9enp3k') format('woff'),
    url('fonts/icomoon.svg?9enp3k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-backward2:before {
  content: "\ea1f";
}
.icon-forward3:before {
  content: "\ea20";
}
.icon-amazon:before {
  content: "\ea87";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-pencil2:before {
  content: "\e906";
}


.spoiler{
  background-color: gray;
  color: transparent;
  user-select: none;
}

.spoiler:hover{
  background-color: inherit;
  color: inherit;
}


marquee{
  max-width:95px;
}
