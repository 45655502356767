.rhap_container {
  border-radius:5px;
}

.image-area {
  min-height: 0 !important;
}

.image-area div.game-image {
height: 30px !important;
  min-height:0 !important;
}

.guess-hint {
 padding: 0 !important;
}

.game-info{
    width:100%;
}
